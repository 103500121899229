@use '../../scss/abstracts/mixins' as *;
.user-main-container {
  width: calc(100% - 12px);
  height: 91vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .user-overview-header-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  .MuiTabs-scroller {
    @include align-center();
  }
  .loading-container {
    @include align-center();
    margin-bottom: 5rem;
  }
  .aad-users-container {
    @include align-center();
    margin-top: 5rem;
    .MuiAutocomplete-root {
      width: 30rem;
    }
  }
  .aad-users-loader {
    @include align-center();
    margin-top: 5rem;
  }
  .no-location-msg {
    @include align-center();
    font-weight: 600;
    margin-top: 5rem;
  }
  .add-location-btn {
    @include align-center();
    button {
      background-color: #234c8a;
      margin-top: 1rem;
    }
  }
}

.user-overview-row-container {
  display: flex;
  width: 100% !important;
  float: left;
  height: 100px !important;
  margin: 0 !important;
}

.user-details-title {
  font-size: 21pt;
  color: #383838;
  font-weight: bold;
  width: 70%;
}

.new-user-button-container {
  width: 10%;
}
.new-user-button-container-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.location-select-user {
  @include align-center();
  margin-top: 5rem;
}

.button-wrapper {
  margin-top: -50px;
}
.user-type-select {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .MuiFormControl-root {
    width: 100%;
  }
}
.no-user-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #383838;
  padding-bottom: 30px;
  font-weight: bold;
}
